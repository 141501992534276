import React, { useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import ThemeColor from '../../../assets/theme/ThemeColor'
import { checkNumberValidity } from './AlarmPhoneNumber';
import { useToggleTop } from '../../common/ToggleTop';

const AlarmSystemNumber = ({ data, setOverlay, onClick }) => {
    const [number, setNumber] = useState("");
    const setToggleTop = useToggleTop();

    const validity = checkNumberValidity(number);

    useEffect(() => {
        setNumber(data);
    }, [data])

    return (
        <div
            className='flex-column-center'
            style={{

                background: ThemeColor.bgColor,
                borderRadius: "8px",
                boxShadow: "10p 1px 1px 10px red",
                width: '80%',
                padding: "20px",
                margin: "0 auto",
                gap: "20px"
            }}>
            <div className='flex-row-center' style={{ position: "relative", width: "100%" }}>
                <div style={{ position: "absolute", left: "0px", top: "0px" }}>
                    <IoClose className='cursor-pointer' style={{ fontSize: "1.6em" }} onClick={() => setOverlay("close")} />
                </div>
                <h3>
                    <b>Phone Number of Alarm System</b>
                </h3>
                <div
                    className='cursor-pointer'
                    style={{ position: "absolute", right: "0px", top: "0px", color: ThemeColor.securityRed, fontSize: "1.2em" }}
                    onClick={
                        () => {
                            const valid = checkNumberValidity(number);
                            if(validity) {
                                onClick(String(number));
                                setOverlay("close");
                            }
                            else setToggleTop("Enter Valid Mobile Number.", "red");
                        }
                    }
                >
                    save
                </div>

            </div>
            <div style={{ position: "relative", width: "100%" }}>
                <input
                    style={
                        {
                            width: "100%",
                            display: "block",
                            borderRadius: "8px",
                            padding: "10px",
                            outline: "none",
                            border: validity ? "1px solid lightgrey" : `1px solid ${ThemeColor.securityDarkRed}`,
                            background: ThemeColor.bgColor,
                            fontSize: "1.1em",
                            fontWeight: "600"
                        }
                    }
                    defaultValue={data}
                onChange={
                    (event) => {
                        const num = event.target.value;
                        setNumber(num);
                    }
                }
                />
                {validity ? <></> : <small style={{ position: "absolute", color: "red", bottom: "-7px", background: "white", right: "0px" }}>Enter valid mobile number.</small>}
            </div>
        </div>
    )
}

export default AlarmSystemNumber