// import { BASE_API_URL } from 'react-native-dotenv';

const ApiConfig = {
  baseUrl: "https://api.hashh.io/",
  urls: {
    user: "user",
    getConfigById: "config",
    getTimeline: "logs/date-range",
    getDoorStatusForDay: "logs/openCloseStatusForDay",
    getDoorStatusForMonth: "logs/openCloseStatusForMonth",
    getDoorStatusForYear: "logs/openCloseStatusForYear",
    getProperty: "property",
    getRooms: "config",
    getDeviceTypes: "config",
    updateConfig: "config",
    updateState: "control",
    getLastState: "laststate",
    registerDevice: "device",
    deleteDevice: "device/property",
    routine: "routine",
    rule: "rule",
    scene:"scene",
    subscription: "subscription",
    getKwhByMonth: "logs/kwhmonth-range",
    getWattsByLastOneHour: "logs/wattslastonehour-range",
    getKwhByWeek: "logs/kwhweek-range",
    getHIKCodes: (propertyId) => `hikvision/${propertyId}/codes`,
    getHIKAccessToken: (propertyId) => `hikvision/${propertyId}/token`,
    addHIKDevice: (propertyId) => `hikvision/${propertyId}/device`,
    deleteHIKDevice: (propertyId, deviceID) =>
      `hikvision/${propertyId}/device/${deviceID}`,
    getRemoteCategories: () =>
      `irdb/categories?from=${0}&count=${14}&status=${1}`,
    getRemoteBrands: (categoryId, searchText, from, count) =>
      `irdb/brands?categoryId=${categoryId}&from=${from}${
        searchText?.length !== 0 ? `&search=${searchText}` : ""
      }&count=${count}`,
    getRemotes: (categoryId, brandId) =>
      `irdb/remotes?categoryId=${categoryId}&from=${0}&brandId=${brandId}`,
    decodeRemote: "irdb/decode",
    decodeRemoteById: (propertyId, remoteId) =>
      `irdb/remote/${propertyId}/data/${remoteId}`,
    addRemote: (propertyId) => `irdb/remote/add/${propertyId}`,
    getRemoteData: (propertyId, remoteId) =>
      `irdb/remote/${propertyId}/data/${remoteId}`,
    deleteRemote: (propertyId, remoteId) =>
      `irdb/remote/${propertyId}/delete/${remoteId}`,
    renameRemote: (propertyId) => `irdb/remote/${propertyId}/rename`,
    updateRemoteKey: (propertyId, remoteId) =>
      `irdb/remote/${propertyId}/key/${remoteId}`,
  },
};

export default ApiConfig;
