import { CheckCircleFilled, RightOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import ToggleSwitch from '../../common/ToggleSwitch'
import CircleBarSpinner from '../../common/CircleBarSpinner'
import Overlay from '../../common/Overlay'
import DotSpinner from '../../common/DotSpinner'
import { IoClose } from 'react-icons/io5'
import ThemeColor from '../../../assets/theme/ThemeColor'
import { FaRegCircle } from "react-icons/fa6";
import WiredSensorTypeSettings from './WiredSensorTypeSettings'
import SirenDurationTime from './SirenDurationTime'
import AlarmPhoneNumber from './AlarmPhoneNumber'
import AlarmSystemNumber from './AlarmSystemNumber'
import ExitEntryDelay from './ExitEntryDelay'

const Settings = ({ device, setDevice, sendPayload }) => {

    const [overlay, setOverlay] = useState("");
    const [activeSetting, setActiveSetting] = useState(null);

    const data = [
        {
            id: "wz-s-1",
            title: "Door Sensor Monitoring",
            description: "Get a Notification if a door or window is left open after arming the alarm system.",
            isSwitch: true,
            image: "/security_device/door_sensor.png",
            child: <DotSpinner />,
            checked: device?.settings?.door_monitor === "1" ? true : false,

            onChange: function () {
                const value = device.settings.door_monitor === "1" ? "0" : "1";
                setDevice({ door_monitor: value });
                if (value === "0" || value === "1") {
                    const payload = {
                        on: [device.applianceId],
                        off: [],
                        action: {
                            [device.applianceId]: {
                                "id": 4,
                                "trait": 1305,
                                "value": Number(value)
                            }
                        }
                    }
                    sendPayload(payload, true);
                } 
            }
        },
        {
            id: "wz-s-2",
            title: "Alarm Type",
            description: "Get a Notification if a door or window is left open after arming the alarm system.",
            isSwitch: true,
            checked: device?.settings?.alarm === "1" ? true : false,
            image: "/security_device/alarm.png",
            onChange: function () {
                const value = device.settings.alarm === "1" ? "0" : "1";
                setDevice({ alarm: value });
                const payload = {
                    on: [device.applianceId],
                    off: [],
                    action: {
                        [device.applianceId]: {
                            "id": 6,
                            "trait": 1305,
                            "value": Number(value)
                        }
                    }
                }
                sendPayload(payload);
            }
        },
        {
            id: "wz-s-3",
            title: "Local Siren",
            // description: "Control Emergency siren.",
            isSwitch: true,
            checked: device?.settings?.local_siren === "1" ? true : false,
            image: "/security_device/siren.png",
            
            onChange: function () {
                const value = device.settings.local_siren === "1" ? "0" : "1";
                setDevice({ local_siren: value });
                const payload = {
                    on: [device.applianceId],
                    off: [],
                    action: {
                        [device.applianceId]: {
                            id: 7,
                            trait: 1305,
                            value: Number(value),
                        }
                    }
                }
                sendPayload(payload);
            }
        },
        {
            id: "wz-s-4",
            title: "GSM Signal Monitoring",
            description: "Get a Notification if a door or window is left open after arming the alarm system.",
            isSwitch: true,
            checked: device?.settings?.gsm_monitor === "1" ? true : false,
            image: "/security_device/gsm.png",
            
            onChange: function () {
                const value = device.settings.gsm_monitor === "1" ? "0" : "1";
                setDevice({ gsm_monitor: value });
                const payload = {
                    on: [device.applianceId],
                    off: [],
                    action: {
                        [device.applianceId]: {
                            "id": 2,
                            "trait": 1305,
                            "value": Number(value)
                        }
                    }
                }
                sendPayload(payload);
            }
        },
        {
            id: "wz-s-5",
            title: "Remote Control Armed/Disarmed Alert",
            description: "Get a Notification if a door or window is left open after arming the alarm system.",
            isSwitch: true,
            checked: device?.settings?.remote_alert === "1" ? true : false,
            image: "/security_device/remote.png",
            
            onChange: function () {
                const value = device.settings.remote_alert === "1" ? "0" : "1";
                setDevice({ remote_alert: value });
                const payload = {
                    on: [device.applianceId],
                    off: [],
                    action: {
                        [device.applianceId]: {
                            "id": 3,
                            "trait": 1305,
                            "value": Number(value)
                        }
                    }
                }
                sendPayload(payload);
            }
        },
        {
            id: "wz-s-6",
            title: "Wired Sensor Type",
            isSwitch: false,
            buttonText: "N.O",
            image: "/security_device/wired_sensor.png",
            
            onClick: function () {
                setActiveSetting("wz-s-6");
            },
            subSetting: <WiredSensorTypeSettings data={device?.settings?.wired_type} setOverlay={setOverlay}
                onClick={
                    (value) => {
                        setDevice({ wired_type: value });
                        const payload = {
                            on: [device.applianceId],
                            off: [],
                            action: {
                                [device.applianceId]: {
                                    "id": 5,
                                    "trait": 1305,
                                    "value": Number(value)
                                }
                            }
                        }
                        sendPayload(payload, true);
                    }} />
        },
        {
            id: "wz-s-7",
            title: "Siren Duration Time",
            isSwitch: false,
            buttonText: "5 sec",
            image: "/security_device/siren_duration.png",
            
            onClick: function () {
                setActiveSetting("wz-s-7");
            },
            subSetting: <SirenDurationTime data={device?.settings?.siren_duration} setOverlay={setOverlay}
                onClick={(value) => {
                    setDevice({ siren_duration: value })
                    const payload = {
                        on: [device.applianceId],
                        off: [],
                        action: {
                            [device.applianceId]: {
                                "id": 8,
                                "trait": 1305,
                                "value": Number(value)
                            }
                        }
                    }
                    sendPayload(payload);
                }} />

        },
        {
            id: "wz-s-8",
            title: "Store Alarm Phone Number",
            isSwitch: false,
            image: "/security_device/store_number.png",
            onClick: function () {
                setActiveSetting("wz-s-8");
            },
            subSetting:
                <AlarmPhoneNumber
                    data={{
                        phone_1: device?.settings?.phone_1,
                        phone_2: device?.settings?.phone_2,
                        phone_3: device?.settings?.phone_3,
                        phone_4: device?.settings?.phone_4,
                        phone_5: device?.settings?.phone_5,
                        sms_1: device?.settings?.sms_1,
                        sms_2: device?.settings?.sms_2,
                        sms_3: device?.settings?.sms_3,
                    }}
                    setOverlay={setOverlay}
                    onClick={function (value, id, index, number) {
                        setDevice({ ...value })
                        const payload = {
                            on: [device.applianceId],
                            off: [],
                            action: {
                                [device.applianceId]: {
                                    id,
                                    trait: 1306,
                                    value: id === 3 ? index : {
                                        num_index: index,
                                        num: number
                                    }
                                }
                            }
                        }
                        sendPayload(payload, true);
                    }}
                />
        },
        {
            id: "wz-s-9",
            title: "Phone Number of Alarm System",
            isSwitch: false,
            image: "/security_device/phone.png",
            
            onClick: function () {
                setActiveSetting("wz-s-9");
            },
            subSetting: <AlarmSystemNumber data={device?.settings?.alarm_phone_number} setOverlay={setOverlay}
                onClick={(value) => {
                    setDevice({ alarm_phone_number: value });
                    setActiveSetting("wz-s-9");
                    const payload = {
                        on: [device.applianceId],
                        off: [],
                        action: {
                            [device.applianceId]: {
                                id: 9,
                                trait: 1305,
                                value: value
                            }
                        }
                    }
                    sendPayload(payload);
                }} />
        },
        {
            id: "wz-s-10",
            title: "Exit / Entry Delay",
            isSwitch: false,
            image: "/security_device/exit_entry_delay.png",
            onClick: function (value) {
                setActiveSetting("wz-s-10");
            },
            subSetting: <ExitEntryDelay entry_delay={device?.settings?.entry_delay} exit_delay={device?.settings?.exit_delay} setOverlay={setOverlay}
                onClick={(exit_delay, entry_delay) => {
                    const payload = {
                        on: [device.applianceId],
                        off: [],
                        action: {
                            [device.applianceId]: {
                                "id": 1,
                                "trait": 1305,
                                "value": {
                                    "exit": Number(exit_delay),
                                    "entry": Number(entry_delay)
                                }
                            }
                        }
                    }
                    sendPayload(payload);
                    setDevice({ exit_delay, entry_delay })
                }} />
        },

    ]

    return (
        <>

            {Object.keys(device).length ?
                <div style={{ display: "flex", flexDirection: "column", gap: "20px", padding: "10px" }}>
                    {data?.map((item, index) => <div key={item.id}>
                        {item.id === activeSetting && <Overlay overlay={overlay} setOverlay={setOverlay} closeBtn={false}>
                            {item.subSetting ? item.subSetting : <CircleBarSpinner />}
                        </Overlay>}
                        <div style={styles.contentsPrimary}>
                            <div className='flex-row-start' style={{ gap: "20px" }}>
                                {/* <div className='image-container'><img src={item.image} className='tinted-image' /></div>
                         */}
                                <div className="image-container">
                                    <img src={item.image} alt="example" className="base-image" />
                                </div>
                                <div className='flex-column-start' style={{ gap: "2px", justifyContent: "center" }}>
                                    <h4><b>{item.title}</b></h4>
                                    {item.description && <p style={{ fontSize: "0.8em", display: "inline" }}>
                                        {item.description}
                                    </p>}
                                </div>
                            </div>
                            {item.isSwitch ?
                                <div className='flex-column-center'>
                                    <ToggleSwitch color={"red"} checked={item.checked} onChange={item.onChange} />
                                </div>
                                :
                                <div className='flex-column-center cursor-pointer' onClick={() => { setOverlay("open"); item.onClick() }}>
                                    <RightOutlined />
                                </div>
                            }
                        </div>
                    </div>

                    )}
                </div>
                :
                <CircleBarSpinner />
            }
        </>
    )
}

const styles = {
    contentsParent: {
        display: "flex",
        gap: "20px"
    },
    contentsPrimary: {
        display: "flex",
        justifyContent: "space-between",
        gap: "30px",
        background: "#ffffff",
        padding: "20px",
        borderRadius: "12px"
    }
}

export default Settings

// alarm
// : 
// "1"
// alarm_phone_number
// : 
// "9361305883"
// door_monitor
// : 
// "1"
// entry_delay
// : 
// "3"
// exit_delay
// : 
// "3"
// gsm_monitor
// : 
// "0"
// local_siren
// : 
// "0"
// phone_1
// : 
// "9952373897"
// phone_2
// : 
// ""
// phone_3
// : 
// ""
// phone_4
// : 
// ""
// phone_5
// : 
// ""
// remote_alert
// : 
// "1"
// siren_duration
// : 
// "5"
// sms_1
// : 
// "9952373897"
// sms_2
// : 
// ""
// sms_3
// : 
// ""
// speed_1
// : 
// ""
// speed_2
// : 
// ""
// wired_type
// : 
// "0"