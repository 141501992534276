import React, { useEffect, useRef, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import ThemeColor from '../../../assets/theme/ThemeColor'
import { RiDeleteBin6Line } from "react-icons/ri";
import { useToggleTop } from '../../common/ToggleTop';

export const checkNumberValidity = (number, notNull = false) => {
    const sanitizedNumber = number;
    if (notNull)
        return sanitizedNumber.match('^[6-9]\\d{9}$');
    else
        return sanitizedNumber.match('^[6-9]\\d{9}$') ? true : sanitizedNumber === '' || sanitizedNumber === null;
}

const AlarmPhoneNumber = ({ data, setOverlay, onClick }) => {

    const { phone_1, phone_2, phone_3, phone_4, phone_5, sms_1, sms_2, sms_3 } = data;

    const [numbers, setNumbers] = useState({
        phone_1: "",
        phone_2: "",
        phone_3: "",
        phone_4: "",
        phone_5: "",
        sms_1: "",
        sms_2: "",
        sms_3: "",
    });
    const [active, setActive] = useState(null);
    const setToggleTop = useToggleTop();

    const phone_1_ref = useRef();
    const phone_2_ref = useRef();
    const phone_3_ref = useRef();
    const phone_4_ref = useRef();
    const phone_5_ref = useRef();
    const sms_1_ref = useRef();
    const sms_2_ref = useRef();
    const sms_3_ref = useRef();

    const callNumbers = [
        {
            id: "c-1",
            payloadIndex: 4,
            icon: <Call1 />,
            defaultValue: numbers.phone_1,
            onchange: (event) => {
                numbers.phone_1 = event.target.value;
                setNumbers({ ...numbers });
            },
            ref: phone_1_ref,
            localState: "phone_1"
        },
        {
            id: "c-2",
            payloadIndex: 5,
            icon: <Call2 />,
            defaultValue: numbers.phone_2,
            onchange: (event) => {
                numbers.phone_2 = event.target.value;
                setNumbers({ ...numbers });
                // setVALIDITY(checkNumberValidity(event.target.value));
            },
            ref: phone_2_ref,
            localState: "phone_2"
        },
        {
            id: "c-3",
            payloadIndex: 6,
            icon: <Call3 />,
            defaultValue: numbers.phone_3,
            onchange: (event) => {
                numbers.phone_3 = event.target.value;
                setNumbers({ ...numbers });
                // setVALIDITY(checkNumberValidity(event.target.value));
            },
            ref: phone_3_ref,
            localState: "phone_3"
        },
        {
            id: "c-4",
            payloadIndex: 7,
            icon: <Call4 />,
            defaultValue: numbers.phone_4,
            onchange: (event) => {
                numbers.phone_4 = event.target.value;
                setNumbers({ ...numbers });
                // setVALIDITY(checkNumberValidity(event.target.value));
            },
            ref: phone_4_ref,
            localState: "phone_4"
        },
        {
            id: "c-5",
            icon: <Call5 />,
            payloadIndex: 8,
            defaultValue: numbers.phone_5,
            onchange: (event) => {
                numbers.phone_5 = event.target.value;
                setNumbers({ ...numbers });
                // setVALIDITY(checkNumberValidity(event.target.value));
            },
            ref: phone_5_ref,
            localState: "phone_5"
        },

    ]

    const smsNumbers = [
        {
            id: "s-1",
            payloadIndex: 1,
            icon: <SMS1 />,
            defaultValue: numbers.sms_1,
            onchange: (event) => {
                numbers.sms_1 = event.target.value;
                setNumbers({ ...numbers });
                // setVALIDITY(checkNumberValidity(event.target.value));
            },
            ref: sms_1_ref,
            localState: "sms_1"
        },
        {
            id: "s-2",
            payloadIndex: 2,
            icon: <SMS2 />,
            defaultValue: numbers.sms_2,
            onchange: (event) => {
                numbers.sms_2 = event.target.value;
                setNumbers({ ...numbers });
                // setVALIDITY(checkNumberValidity(event.target.value));
            },
            ref: sms_2_ref,
            localState: "sms_2"
        },
        {
            id: "s-3",
            payloadIndex: 3,
            icon: <SMS3 />,
            defaultValue: numbers.sms_3,
            onchange: (event) => {
                numbers.sms_3 = event.target.value;
                setNumbers({ ...numbers });
                // setVALIDITY(checkNumberValidity(event.target.value));
            },
            ref: sms_3_ref,
            localState: "sms_3"
        },
    ]

    useEffect(() => {
        setActive(null);
        setNumbers({
            phone_1: phone_1 || "",
            phone_2: phone_2 || "",
            phone_3: phone_3 || "",
            phone_4: phone_4 || "",
            phone_5: phone_5 || "",
            sms_1: sms_1 || "",
            sms_2: sms_2 || "",
            sms_3: sms_3 || "",
        });
    }, [data]);
    // console.log(phone_2, phone_3, phone_3, phone_4, phone_5)
    return (
        <div
            className='flex-column-center'
            style={{

                background: ThemeColor.bgColor,
                borderRadius: "8px",
                boxShadow: "10p 1px 1px 10px red",
                width: '80%',
                padding: "20px",
                margin: "0 auto",
                gap: "20px"
            }}>
            <div className='flex-row-center' style={{ position: "relative", width: "100%" }}>
                <div style={{ position: "absolute", left: "0px", top: "0px" }}>
                    <IoClose className='cursor-pointer' style={{ fontSize: "1.6em" }} onClick={() => setOverlay("close")} />
                </div>
                <h3>
                    <b>Store Alarm Phone Numbers</b>
                </h3>
                {/* <div
                    className='cursor-pointer'
                    style={{ position: "absolute", right: "0px", top: "0px", color: ThemeColor.securityRed, fontSize: "1.2em" }}
                    onClick={
                        handleSave
                    }
                >
                    save
                </div> */}
            </div>
            <div className='flex-column-center' style={{ width: "100%", gap: "20px" }}>

                {/* sms */}
                <div className='flex-column-start' style={{ borderRadius: "12px", background: "white", gap: "15px", width: "100%", padding: "5px 10px" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "start", padding: "5px", gap: "20px" }}>
                        <div className='flex-column-center' style={{ marginTop: "5px" }}>
                            <SMS />
                        </div>
                        <div style={{ color: ThemeColor.securityDarkRed, fontSize: "1.2em", fontWeight: "600" }}>Add Phone Numbers to receive SMS alerts</div>
                    </div>
                    {smsNumbers.map((item, index) => {
                        // const validity = ((numbers[item.localState].match('^[789]\d{9}$')) || numbers[item.localState]==="" || numbers[item.localState]===null);
                        const validity = checkNumberValidity(numbers[item.localState], true);

                        // console.log("first", validity);
                        return (<div key={item.id} className='flex-row-start' style={{ padding: "5px", gap: "20px", width: "100%", flex: 1 }}>
                            <div>{item.icon}</div>
                            <div style={{ position: "relative" }} onClick={() => setActive(item.localState)}>
                                <input
                                    ref={item.ref}
                                    style={
                                        {
                                            width: "100%",
                                            display: "block",
                                            borderRadius: "8px",
                                            padding: "10px",
                                            outline: "none",
                                            border: (active === index && !validity) ? `1px solid ${ThemeColor.securityDarkRed}` : `1px solid lightgrey`,
                                            background: ThemeColor.bgColor,
                                            fontSize: "1.1em",
                                            fontWeight: "600"
                                        }
                                    }
                                    defaultValue={item.defaultValue}
                                    onChange={item.onchange}
                                />
                                {active === item.localState &&
                                    <>
                                        {validity && <RiDeleteBin6Line
                                            onClick={() => {
                                                item.ref.current.value = "";
                                                onClick({ [item.localState]: 'C' }, 3, item.payloadIndex, numbers[item.localState]);
                                            }}
                                            className='cursor-pointer' style={{ fontSize: "1.7em", color: "red", position: "absolute", right: "10px", top: "10px" }} />}
                                        {validity ?
                                            <small
                                                style={{ position: "absolute", color: ThemeColor.securityDarkRed, top: "10px", right: "-45px", fontSize: "1.2em" }}
                                                onClick={() => {
                                                    onClick({ [item.localState]: numbers[item.localState] }, 2, item.payloadIndex, numbers[item.localState]);
                                                }}
                                            >save</small>
                                            :
                                            <small style={{ position: "absolute", color: "red", bottom: "-7px", background: "white", right: "2px" }}>Enter valid mobile number.</small>
                                        }
                                    </>
                                }
                            </div>
                        </div>)
                    })}

                </div>

                {/* call */}
                <div className='flex-column-start' style={{ borderRadius: "12px", background: "white", gap: "15px", width: "100%", padding: "5px 10px" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "start", padding: "5px", gap: "20px" }}>
                        <div style={{ marginTop: "5px" }}>
                            <Call />
                        </div>
                        <div style={{ color: ThemeColor.securityDarkRed, fontSize: "1.2em", fontWeight: "600" }}>Add Phone Numbers to receive alarm calls</div>
                    </div>
                    {callNumbers.map((item, index) => {
                        // const validity = ((numbers[item.localState].match('^[789]\d{9}$')) || numbers[item.localState]==="" || numbers[item.localState]===null);
                        const validity = checkNumberValidity(numbers[item.localState], true);

                        // console.log("first", validity);
                        return (<div key={item.id} className='flex-row-start' style={{ padding: "5px", gap: "20px", width: "100%", flex: 1 }}>
                            <div>{item.icon}</div>
                            <div style={{ position: "relative" }} onClick={() => setActive(item.localState)}>
                                <input
                                    ref={item.ref}
                                    style={
                                        {
                                            width: "100%",
                                            display: "block",
                                            borderRadius: "8px",
                                            padding: "10px",
                                            outline: "none",
                                            border: (active === index && !validity) ? `1px solid ${ThemeColor.securityDarkRed}` : `1px solid lightgrey`,
                                            background: ThemeColor.bgColor,
                                            fontSize: "1.1em",
                                            fontWeight: "600"
                                        }
                                    }
                                    defaultValue={item.defaultValue}
                                    onChange={item.onchange}
                                />
                                {active === item.localState &&
                                    <>
                                        {validity && <RiDeleteBin6Line
                                            onClick={() => {
                                                item.ref.current.value = "";
                                                onClick({ [item.localState]: 'C' }, 3, item.payloadIndex, numbers[item.localState]);
                                            }}
                                            className='cursor-pointer' style={{ fontSize: "1.7em", color: "red", position: "absolute", right: "10px", top: "10px" }} />}
                                        {validity ?
                                            <small
                                                style={{ position: "absolute", color: ThemeColor.securityDarkRed, top: "10px", right: "-45px", fontSize: "1.2em" }}
                                                onClick={() => {
                                                    onClick({ [item.localState]: numbers[item.localState] }, 2, item.payloadIndex, numbers[item.localState]);
                                                }}
                                            >save</small>
                                            :
                                            <small style={{ position: "absolute", color: "red", bottom: "-7px", background: "white", right: "2px" }}>Enter valid mobile number.</small>
                                        }
                                    </>
                                }
                            </div>
                        </div>)
                    })}


                </div>
            </div>

        </div>
    )
}

const Call = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6667 12.5H19.4444C19.4444 10.6582 18.7128 8.89187 17.4105 7.58954C16.1081 6.2872 14.3418 5.55556 12.5 5.55556V8.33333C13.6051 8.33333 14.6649 8.77232 15.4463 9.55372C16.2277 10.3351 16.6667 11.3949 16.6667 12.5ZM22.2222 12.5H25C25 5.55556 19.4028 0 12.5 0V2.77778C17.8611 2.77778 22.2222 7.125 22.2222 12.5ZM23.6111 17.3611C21.875 17.3611 20.2083 17.0833 18.6528 16.5694C18.1667 16.4167 17.625 16.5278 17.2361 16.9167L14.1806 19.9722C10.25 17.9722 7.02778 14.75 5.02778 10.8194L8.08333 7.76389C8.47222 7.375 8.58333 6.83333 8.43055 6.34722C7.91667 4.79167 7.63889 3.125 7.63889 1.38889C7.63889 1.02053 7.49256 0.667263 7.23209 0.406796C6.97162 0.146329 6.61836 0 6.25 0H1.38889C1.02053 0 0.667263 0.146329 0.406796 0.406796C0.146329 0.667263 0 1.02053 0 1.38889C0 7.65094 2.48759 13.6565 6.91553 18.0845C11.3435 22.5124 17.3491 25 23.6111 25C23.9795 25 24.3327 24.8537 24.5932 24.5932C24.8537 24.3327 25 23.9795 25 23.6111V18.75C25 18.3816 24.8537 18.0284 24.5932 17.7679C24.3327 17.5074 23.9795 17.3611 23.6111 17.3611Z" fill="#980204" />
        </svg>

    )
}
const Call1 = () => {
    return (
        <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.62 11.79C5.06 14.62 7.38 16.94 10.21 18.38L12.41 16.18C12.69 15.9 13.08 15.82 13.43 15.93C14.55 16.3 15.75 16.5 17 16.5C17.2652 16.5 17.5196 16.6054 17.7071 16.7929C17.8946 16.9804 18 17.2348 18 17.5V21C18 21.2652 17.8946 21.5196 17.7071 21.7071C17.5196 21.8946 17.2652 22 17 22C12.4913 22 8.1673 20.2089 4.97918 17.0208C1.79107 13.8327 0 9.50868 0 5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4H4.5C4.76522 4 5.01957 4.10536 5.20711 4.29289C5.39464 4.48043 5.5 4.73478 5.5 5C5.5 6.25 5.7 7.45 6.07 8.57C6.18 8.92 6.1 9.31 5.82 9.59L3.62 11.79Z" fill="#980204" />
            <path d="M15.27 5.29V3.7H18.04V11H16.26V5.29H15.27Z" fill="#980204" />
        </svg>


    )
}
const Call2 = () => {
    return (
        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.62 11.79C5.06 14.62 7.38 16.94 10.21 18.38L12.41 16.18C12.69 15.9 13.08 15.82 13.43 15.93C14.55 16.3 15.75 16.5 17 16.5C17.2652 16.5 17.5196 16.6054 17.7071 16.7929C17.8946 16.9804 18 17.2348 18 17.5V21C18 21.2652 17.8946 21.5196 17.7071 21.7071C17.5196 21.8946 17.2652 22 17 22C12.4913 22 8.1673 20.2089 4.97918 17.0208C1.79107 13.8327 0 9.50868 0 5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4H4.5C4.76522 4 5.01957 4.10536 5.20711 4.29289C5.39464 4.48043 5.5 4.73478 5.5 5C5.5 6.25 5.7 7.45 6.07 8.57C6.18 8.92 6.1 9.31 5.82 9.59L3.62 11.79Z" fill="#980204" />
            <path d="M12.39 9.63C12.6167 9.45 12.72 9.36667 12.7 9.38C13.3533 8.84 13.8667 8.39667 14.24 8.05C14.62 7.70333 14.94 7.34 15.2 6.96C15.46 6.58 15.59 6.21 15.59 5.85C15.59 5.57667 15.5267 5.36333 15.4 5.21C15.2733 5.05667 15.0833 4.98 14.83 4.98C14.5767 4.98 14.3767 5.07667 14.23 5.27C14.09 5.45667 14.02 5.72333 14.02 6.07H12.37C12.3833 5.50333 12.5033 5.03 12.73 4.65C12.9633 4.27 13.2667 3.99 13.64 3.81C14.02 3.63 14.44 3.54 14.9 3.54C15.6933 3.54 16.29 3.74333 16.69 4.15C17.0967 4.55667 17.3 5.08667 17.3 5.74C17.3 6.45333 17.0567 7.11667 16.57 7.73C16.0833 8.33667 15.4633 8.93 14.71 9.51H17.41V10.9H12.39V9.63Z" fill="#980204" />
        </svg>

    )
}
const Call3 = () => {
    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.62 11.79C5.06 14.62 7.38 16.94 10.21 18.38L12.41 16.18C12.69 15.9 13.08 15.82 13.43 15.93C14.55 16.3 15.75 16.5 17 16.5C17.2652 16.5 17.5196 16.6054 17.7071 16.7929C17.8946 16.9804 18 17.2348 18 17.5V21C18 21.2652 17.8946 21.5196 17.7071 21.7071C17.5196 21.8946 17.2652 22 17 22C12.4913 22 8.1673 20.2089 4.97918 17.0208C1.79107 13.8327 0 9.50868 0 5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4H4.5C4.76522 4 5.01957 4.10536 5.20711 4.29289C5.39464 4.48043 5.5 4.73478 5.5 5C5.5 6.25 5.7 7.45 6.07 8.57C6.18 8.92 6.1 9.31 5.82 9.59L3.62 11.79Z" fill="#980204" />
            <path d="M13.51 5.76C13.5367 5.04667 13.77 4.49667 14.21 4.11C14.65 3.72333 15.2467 3.53 16 3.53C16.5 3.53 16.9267 3.61667 17.28 3.79C17.64 3.96333 17.91 4.2 18.09 4.5C18.2767 4.8 18.37 5.13667 18.37 5.51C18.37 5.95 18.26 6.31 18.04 6.59C17.82 6.86333 17.5633 7.05 17.27 7.15V7.19C17.65 7.31667 17.95 7.52667 18.17 7.82C18.39 8.11333 18.5 8.49 18.5 8.95C18.5 9.36333 18.4033 9.73 18.21 10.05C18.0233 10.3633 17.7467 10.61 17.38 10.79C17.02 10.97 16.59 11.06 16.09 11.06C15.29 11.06 14.65 10.8633 14.17 10.47C13.6967 10.0767 13.4467 9.48333 13.42 8.69H15.08C15.0867 8.98333 15.17 9.21667 15.33 9.39C15.49 9.55667 15.7233 9.64 16.03 9.64C16.29 9.64 16.49 9.56667 16.63 9.42C16.7767 9.26667 16.85 9.06667 16.85 8.82C16.85 8.5 16.7467 8.27 16.54 8.13C16.34 7.98333 16.0167 7.91 15.57 7.91H15.25V6.52H15.57C15.91 6.52 16.1833 6.46333 16.39 6.35C16.6033 6.23 16.71 6.02 16.71 5.72C16.71 5.48 16.6433 5.29333 16.51 5.16C16.3767 5.02667 16.1933 4.96 15.96 4.96C15.7067 4.96 15.5167 5.03667 15.39 5.19C15.27 5.34333 15.2 5.53333 15.18 5.76H13.51Z" fill="#980204" />
        </svg>


    )
}
const Call4 = () => {
    return (
        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.62 11.79C5.06 14.62 7.38 16.94 10.21 18.38L12.41 16.18C12.69 15.9 13.08 15.82 13.43 15.93C14.55 16.3 15.75 16.5 17 16.5C17.2652 16.5 17.5196 16.6054 17.7071 16.7929C17.8946 16.9804 18 17.2348 18 17.5V21C18 21.2652 17.8946 21.5196 17.7071 21.7071C17.5196 21.8946 17.2652 22 17 22C12.4913 22 8.1673 20.2089 4.97918 17.0208C1.79107 13.8327 0 9.50868 0 5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4H4.5C4.76522 4 5.01957 4.10536 5.20711 4.29289C5.39464 4.48043 5.5 4.73478 5.5 5C5.5 6.25 5.7 7.45 6.07 8.57C6.18 8.92 6.1 9.31 5.82 9.59L3.62 11.79Z" fill="#980204" />
            <path d="M11.49 9.7V8.32L14.65 3.8H16.56V8.22H17.38V9.7H16.56V11H14.85V9.7H11.49ZM14.97 5.68L13.29 8.22H14.97V5.68Z" fill="#980204" />
        </svg>


    )
}
const Call5 = () => {
    return (
        <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.62 11.79C5.06 14.62 7.38 16.94 10.21 18.38L12.41 16.18C12.69 15.9 13.08 15.82 13.43 15.93C14.55 16.3 15.75 16.5 17 16.5C17.2652 16.5 17.5196 16.6054 17.7071 16.7929C17.8946 16.9804 18 17.2348 18 17.5V21C18 21.2652 17.8946 21.5196 17.7071 21.7071C17.5196 21.8946 17.2652 22 17 22C12.4913 22 8.1673 20.2089 4.97918 17.0208C1.79107 13.8327 0 9.50868 0 5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4H4.5C4.76522 4 5.01957 4.10536 5.20711 4.29289C5.39464 4.48043 5.5 4.73478 5.5 5C5.5 6.25 5.7 7.45 6.07 8.57C6.18 8.92 6.1 9.31 5.82 9.59L3.62 11.79Z" fill="#980204" />
            <path d="M17.59 5.19H14.47V6.57C14.6033 6.42333 14.79 6.30333 15.03 6.21C15.27 6.11667 15.53 6.07 15.81 6.07C16.31 6.07 16.7233 6.18333 17.05 6.41C17.3833 6.63667 17.6267 6.93 17.78 7.29C17.9333 7.65 18.01 8.04 18.01 8.46C18.01 9.24 17.79 9.86 17.35 10.32C16.91 10.7733 16.29 11 15.49 11C14.9567 11 14.4933 10.91 14.1 10.73C13.7067 10.5433 13.4033 10.2867 13.19 9.96C12.9767 9.63333 12.86 9.25667 12.84 8.83H14.51C14.55 9.03667 14.6467 9.21 14.8 9.35C14.9533 9.48333 15.1633 9.55 15.43 9.55C15.7433 9.55 15.9767 9.45 16.13 9.25C16.2833 9.05 16.36 8.78333 16.36 8.45C16.36 8.12333 16.28 7.87333 16.12 7.7C15.96 7.52667 15.7267 7.44 15.42 7.44C15.1933 7.44 15.0067 7.49667 14.86 7.61C14.7133 7.71667 14.6167 7.86 14.57 8.04H12.92V3.69H17.59V5.19Z" fill="#980204" />
        </svg>


    )
}

const SMS = () => {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_3101_336)">
                <path d="M22.75 12.5V1.25C22.75 0.918479 22.6183 0.600537 22.3839 0.366116C22.1495 0.131696 21.8315 0 21.5 0H5.25C4.91848 0 4.60054 0.131696 4.36612 0.366116C4.1317 0.600537 4 0.918479 4 1.25V18.75L9 13.75H21.5C21.8315 13.75 22.1495 13.6183 22.3839 13.3839C22.6183 13.1495 22.75 12.8315 22.75 12.5ZM27.75 5H25.25V16.25H9V18.75C9 19.0815 9.1317 19.3995 9.36612 19.6339C9.60054 19.8683 9.91848 20 10.25 20H24L29 25V6.25C29 5.91848 28.8683 5.60054 28.6339 5.36612C28.3995 5.1317 28.0815 5 27.75 5Z" fill="#980204" />
            </g>
            <defs>
                <filter id="filter0_d_3101_336" x="0" y="0" width="33" height="33" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3101_336" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3101_336" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}

const SMS1 = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H16L20 20V2C20 0.9 19.1 0 18 0ZM18 15.2L16.8 14H2V2H18V15.2Z" fill="#980204" />
            <path d="M8.27 6.29V4.7H11.04V12H9.26V6.29H8.27Z" fill="#980204" />
        </svg>


    )
}

const SMS2 = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H16L20 20V2C20 0.9 19.1 0 18 0ZM18 15.2L16.8 14H2V2H18V15.2Z" fill="#980204" />
            <path d="M7.39 10.63C7.61667 10.45 7.72 10.3667 7.7 10.38C8.35333 9.84 8.86667 9.39667 9.24 9.05C9.62 8.70333 9.94 8.34 10.2 7.96C10.46 7.58 10.59 7.21 10.59 6.85C10.59 6.57667 10.5267 6.36333 10.4 6.21C10.2733 6.05667 10.0833 5.98 9.83 5.98C9.57667 5.98 9.37667 6.07667 9.23 6.27C9.09 6.45667 9.02 6.72333 9.02 7.07H7.37C7.38333 6.50333 7.50333 6.03 7.73 5.65C7.96333 5.27 8.26667 4.99 8.64 4.81C9.02 4.63 9.44 4.54 9.9 4.54C10.6933 4.54 11.29 4.74333 11.69 5.15C12.0967 5.55667 12.3 6.08667 12.3 6.74C12.3 7.45333 12.0567 8.11667 11.57 8.73C11.0833 9.33667 10.4633 9.93 9.71 10.51H12.41V11.9H7.39V10.63Z" fill="#980204" />
        </svg>


    )
}

const SMS3 = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H16L20 20V2C20 0.9 19.1 0 18 0ZM18 15.2L16.8 14H2V2H18V15.2Z" fill="#980204" />
            <path d="M7.51 6.76C7.53667 6.04667 7.77 5.49667 8.21 5.11C8.65 4.72333 9.24667 4.53 10 4.53C10.5 4.53 10.9267 4.61667 11.28 4.79C11.64 4.96333 11.91 5.2 12.09 5.5C12.2767 5.8 12.37 6.13667 12.37 6.51C12.37 6.95 12.26 7.31 12.04 7.59C11.82 7.86333 11.5633 8.05 11.27 8.15V8.19C11.65 8.31667 11.95 8.52667 12.17 8.82C12.39 9.11333 12.5 9.49 12.5 9.95C12.5 10.3633 12.4033 10.73 12.21 11.05C12.0233 11.3633 11.7467 11.61 11.38 11.79C11.02 11.97 10.59 12.06 10.09 12.06C9.29 12.06 8.65 11.8633 8.17 11.47C7.69667 11.0767 7.44667 10.4833 7.42 9.69H9.08C9.08667 9.98333 9.17 10.2167 9.33 10.39C9.49 10.5567 9.72333 10.64 10.03 10.64C10.29 10.64 10.49 10.5667 10.63 10.42C10.7767 10.2667 10.85 10.0667 10.85 9.82C10.85 9.5 10.7467 9.27 10.54 9.13C10.34 8.98333 10.0167 8.91 9.57 8.91H9.25V7.52H9.57C9.91 7.52 10.1833 7.46333 10.39 7.35C10.6033 7.23 10.71 7.02 10.71 6.72C10.71 6.48 10.6433 6.29333 10.51 6.16C10.3767 6.02667 10.1933 5.96 9.96 5.96C9.70667 5.96 9.51667 6.03667 9.39 6.19C9.27 6.34333 9.2 6.53333 9.18 6.76H7.51Z" fill="#980204" />
        </svg>

    )
}

export default AlarmPhoneNumber