import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { MdOutlet, MdCurtains } from "react-icons/md";
import DeviceSpecific from "../../assets/theme/DeviceSpecificColor";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";
import ThemeColor from "../../assets/theme/ThemeColor";
import { UserService } from "../../services/UserService";
import { User } from "../../storage/store";
import { FaLock } from "react-icons/fa";
import { IoLockClosed, IoLockOpen, IoBulbOutline, IoWaterOutline, IoPowerOutline, IoNavigateCircleOutline, IoShieldCheckmark } from "react-icons/io5";
import { GiGate, GiOpenGate } from "react-icons/gi";
import TimeAgo from "../../pages/helpers.js/TimeAgo";
import CurtainIcon from "../icons/CurtainIcon";
import CurtainDoubleIcon from "../icons/CurtainDoubleIcon";
import GateCloseIcon from "../icons/GateCloseIcon";
import { MdControlCamera } from "react-icons/md";
// import { GiOpenGate } from "react-icons/gi";

export const SecurityDeviceCard = ({ props }) => {
  const devices = props;
  const navigate = useHistory();
  const PropertyId = User.getPropertyId();
  const [isHovering, setIsHovering] = useState(false);
  const [buttonColor, setButtonColor] = useState("white");
  const [textColor, setTextColor] = useState();
  const [iconColor, setIconColor] = useState();
  const [isHolding, setIsHolding] = useState(false);


  let holdTimer;

  const handleMouseDown = () => {
    holdTimer = setTimeout(() => {
      setIsHolding(true);
      navigate.push('securityDetail', { data: devices, propertyId: PropertyId })
      // console.log("On hold!");
    }, 1000);
  };

  const handleMouseUp = () => {
    clearTimeout(holdTimer);
    setIsHolding(false);
  };

  const handlemouseEnter = () => {
    setIsHovering(true);
  };

  const handlemouseLeave = () => {
    setIsHovering(false);
  };

  const header = {
    left: '15px',
    top: '15px',
    background: buttonColor,
    width: "150px",
    height: "180px",
    margin: "10px",
    borderRadius: "20px",
    boxSizing: "borderBox",
    boxShadow: isHovering ? "0px 0px 10px 1px #0006" : "0px 0px 5px 1px #0002",
    border: `1.7px solid transparent`,
    cursor: "pointer",
    position: "relative",
    transition: "1000ms",
    AnimationDuration: "3s",
    AnimationIterationCount: "1",
    AnimationDirection: "reverse",
    appearance: "auto",
    AnimationName: "bounce",
    transition: "box-shadow .20s ease-in-out",
    transition: "background-position 0.5s ease-in-out",
  };

  async function toggleSwitch(devices) {
    navigate.push('securityDetail', { data: devices, propertyId: PropertyId });
  }

  useEffect(() => {
    if (devices) {
      if (devices.props.state === true) {
        setButtonColor(
          DeviceSpecific.getSwitchColor(
            devices.deviceType.toLowerCase(),
            devices.props.state
          )[1]
        );
        setTextColor(ThemeColor.switchTextColor);
        setIconColor(ThemeColor.switchTextColor);
      }
    }
  }, []);

  const [doorStatus, setDoorStatus] = useState(devices.props.state);

  useEffect(() => {
    setDoorStatus(devices.props.state);
  }, [devices.props.state]);
  // console.log(props);

  return (
    <>
      <div
        style={header}
        className="button"
        onClick={() => toggleSwitch(devices)}
        // onMouseEnter={handlemouseEnter}
        // onMouseLeave={handlemouseLeave}
        // onMouseDown={() => {setIsHolding(true); handlemouseEnter()}}
        // onMouseUp={() => {setIsHolding(false);handlemouseLeave()}}
        // onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
      >
        <div className="switch-container-icon">
          {devices ? (
            devices.meta.model === "WZLTEG_1" 
            ? (<IoShieldCheckmark style={{ color: iconColor }} />) 
              :(<MdControlCamera style={{ color: iconColor }} />)
          ) : null}
        </div>
        <div
          style={{ color: "black" }}
        >
          <div
            style={{
              display: 'flex',
              color: textColor,
              marginLeft: "17px",
              height: '40px',
              alignItems: 'start',
              justifyContent: 'start',
              marginBottom: '10px',
              marginTop: '15px',
              overflow: 'hidden',
            }}
          >
            <b style={{ fontSize: '1em', alignSelf: 'end' }}>{devices.name}</b></div>
          <div
            style={{
              color: textColor,
              marginLeft: "17px",
              fontSize: '0.7em',
              height: '15px',
              overflow: 'hidden',
            }}
          >
            <div>{devices.floor}&nbsp;|&nbsp;{devices.room}</div>
          </div>
          <div
            className="sensor-container-device-details"
            style={{
              color: textColor,
              textAlign: "start",
              marginLeft: "17px",
              overflowY: 'hidden',
              padding: '10px 0px 0px 0px',
              fontSize: '0.8em',
            }}
          >
            <div>{TimeAgo.getTimeAgo(devices.props.timeStamp)}</div>
          </div>

        </div>
      </div>
    </>
  );
};
// export default ControllerCard;
