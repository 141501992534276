import React, { useEffect, useState } from 'react'
import { useUser } from '../../../Context/context';
import { RiHome8Fill, RiSettings2Fill } from 'react-icons/ri';
import { AiFillLock, AiFillUnlock } from 'react-icons/ai';
import TimeLineView from '../TimeLineView';
import ThemeColor from '../../../assets/theme/ThemeColor';
import { User } from '../../../storage/store';
import { UserService } from '../../../services/UserService';
import { useToggleTop } from '../../common/ToggleTop';
import SideDrawer from '../../common/SideDrawer';
import Settings from './Settings';

const WZLTEG_1 = () => {

    const { device, setDevice } = useUser();
    const [deviceData, setDevicedata] = useState({});
    const [sos, setSOS] = useState(false);
    const [settingsDrawer, setSettingsDrawer] = useState("");
    const setToggleTop = useToggleTop();

    const handleDeviceData = async () => {
        const propertyId = User.getPropertyId();
        const { deviceId } = device;
        const _ls = await UserService.getLastStatebydeviceId(propertyId, deviceId);
        deviceData.isOnline = _ls.isOnline;
        setDevicedata({ ...deviceData, ..._ls["devices"][deviceId], settings:device.deviceSettings[0].settings});
    }

    const handleDeviceSettings = async ({door_monitor, alarm, gsm_monitor, local_siren, wired_type,remote_alert, siren_duration, alarm_phone_number, phone_1, phone_2, phone_3, phone_4, phone_5, sms_1, sms_2, sms_3, exit_delay, entry_delay}) => {
        if(door_monitor){
            deviceData.settings.door_monitor = door_monitor;
        }
        if(alarm){
            deviceData.settings.alarm = alarm;
        }
        if(gsm_monitor){
            deviceData.settings.gsm_monitor = gsm_monitor;
        }
        if(local_siren) {
            deviceData.settings.local_siren = local_siren
        }
        if(wired_type) {
            deviceData.settings.wired_type = wired_type
        }
        if(remote_alert) {
            deviceData.settings.remote_alert = remote_alert
        }
        if(siren_duration) {
            deviceData.settings.siren_duration = siren_duration
        }
        if(alarm_phone_number) {
            deviceData.settings.alarm_phone_number = alarm_phone_number
        }
        if(phone_1) {
            deviceData.settings.phone_1 = phone_1 === 'C' ? "" : phone_1
        }
        if(phone_2) {
            deviceData.settings.phone_2 = phone_2 === 'C' ? "" : phone_2
        }
        if(phone_3) {
            deviceData.settings.phone_3 = phone_3 === 'C' ? "" : phone_3
        }
        if(phone_4) {
            deviceData.settings.phone_4 = phone_4 === 'C' ? "" : phone_4
        }
        if(phone_5) {
            deviceData.settings.phone_5 = phone_5 === 'C' ? "" : phone_5
        }if(sms_1) {
            deviceData.settings.sms_1 = sms_1 === 'C' ? "" : sms_1
        }if(sms_2) {
            deviceData.settings.sms_2 = sms_2 === 'C' ? "" : sms_2
        }if(sms_3) {
            deviceData.settings.sms_3 = sms_3 === 'C' ? "" : sms_3
        }
        if(entry_delay){
            deviceData.settings.entry_delay = entry_delay;
        }
        if(exit_delay){
            deviceData.settings.exit_delay = exit_delay;
        }
        setDevicedata({...deviceData});
    }

    useEffect(() => {
        handleDeviceData();
        // handleDeviceSettings();
    }, [])
    // console.log(deviceData);

    const sendPayload = async (payload, toggle=false) => {
        const propertyId = User.getPropertyId();
        try {
            const response = await UserService.stateUpdate(payload, propertyId);
            // return true;
            toggle && setToggleTop("Success", ThemeColor.green);
        } catch (err) {
            setToggleTop("Error", "red");
        }
    }

    const handleDeviceMode = (mode) => {
        if (deviceData.isOnline) {
            deviceData.mode = mode;
            setDevicedata({ ...deviceData });
            mode === 2 && setSOS(false);
            const payload = {
                on: [device.applianceId],
                off: [],
                action: {
                    [device.applianceId]: {
                        id: 1,
                        trait: 1301,
                        value: mode
                    }
                }
            }
            sendPayload(payload);
        } else {
            setToggleTop("Device Offline", ThemeColor.pirSecondary)
        }
    }

    const handleDeviceSOS = () => {
        if (deviceData.isOnline) {
            if (sos) {
                handleDeviceMode(2);
                setSOS(false);
                return 0;
            }
            setSOS(true);
            const payload = {
                on: [device.applianceId],
                off: [],
                action: {
                    [device.applianceId]: {
                        id: 2,
                        trait: 1301,
                        value: 1
                    }
                }
            }
            sendPayload(payload);
        } else {
            setToggleTop("Device Offline", ThemeColor.pirSecondary)
        }
    }



    return (
        <>
            <SideDrawer drawer={settingsDrawer} setDrawer={setSettingsDrawer} title={"Settings"} background={ThemeColor.bgColor}>
               <Settings device={deviceData} setDevice={handleDeviceSettings} sendPayload={sendPayload}/>
            </SideDrawer>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: "wrap", padding: '0px 3px 0px 0px' }}>
                <div style={{ position: "relative" }}>
                    <div style={{ position: "absolute", left: "156px", top: "-34px" }}>
                        <RiSettings2Fill
                            id='wzltegSettings'
                            onClick={()=>setSettingsDrawer("open")}
                            style={{ fontSize: 30, marginRight: "2px", cursor: 'pointer' }}
                        />
                    </div>
                </div>
                <div className='flex-column-center' style={{ flex: 1, gap: '50px', padding: "50px 10px 50px 0px" }}>
                    <div className='wzlteg-main-frame' style={{
                        background: `linear-gradient(to bottom, ${ThemeColor.red}, ${ThemeColor.securityRed} )`
                    }}>

                        {/* Away */}
                        <div style={{ width: '110px', height: '100px' }}>
                            {Number(deviceData?.mode) === 0
                                ?
                                <div className='wzlteg-selected-mode-d1'>
                                    <div className='flex-column-center wzlteg-selected-mode-d2' style={{ left: '0px' }}>
                                        <div className='flex-row-center wzlteg-selected-mode-d3'
                                            style={{ background: ThemeColor.securityRed }}>
                                            <div className='wzlteg-selected-mode-d4'>
                                                <AiFillLock style={{ fontSize: '30px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='cursor-pointer' onClick={() => handleDeviceMode(0)} style={{ position: 'relative' }}>
                                    <div className='flex-column-center' style={{ gap: "5px", color: "#d6d6d6", }}>
                                        <AiFillLock style={{ fontSize: '40px' }} />
                                        <p>Away</p>
                                    </div>
                                </div>
                            }
                        </div>


                        {/* Stay */}
                        <div style={{ width: '110px', height: '100px' }}>
                            {Number(deviceData?.mode) === 1
                                ?
                                <div className='wzlteg-selected-mode-d1'>
                                    <div className='flex-column-center wzlteg-selected-mode-d2' style={{ left: "0px" }}>
                                        <div className='flex-row-center wzlteg-selected-mode-d3'
                                            style={{ background: ThemeColor.securityRed }}>
                                            <div className='wzlteg-selected-mode-d4'>
                                                <RiHome8Fill style={{ fontSize: '30px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='cursor-pointer' onClick={() => handleDeviceMode(1)} style={{ position: 'relative' }}>
                                    <div className='flex-column-center' style={{ gap: "5px", color: "#d6d6d6", }}>
                                        <RiHome8Fill style={{ fontSize: '40px' }} />
                                        <p>Stay</p>
                                    </div>
                                </div>
                            }
                        </div>



                        {/* Unarm */}
                        <div style={{ width: '110px', height: '100px' }}>
                            {Number(deviceData?.mode) === 2
                                ?
                                <div className='wzlteg-selected-mode-d1'>
                                    <div className='flex-column-center wzlteg-selected-mode-d2' style={{ left: "0px" }}>
                                        <div className='flex-row-center wzlteg-selected-mode-d3'
                                            style={{ background: ThemeColor.securityRed }}>
                                            <div className='wzlteg-selected-mode-d4'>
                                                <AiFillUnlock style={{ fontSize: '30px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='cursor-pointer' onClick={() => handleDeviceMode(2)} style={{ position: 'relative' }}>
                                    <div className='flex-column-center' style={{ gap: "5px", color: "#d6d6d6" }}>
                                        <AiFillUnlock style={{ fontSize: '40px' }} />
                                        <p>Unarm</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className='flex-row-start ' style={{ gap: '20px' }}>
                        <div
                            onClick={handleDeviceSOS}
                            className={sos ? 'flex-row-center cursor-pointer wzlteg-sos wzlteg-sos-alarming' : 'flex-row-center cursor-pointer wzlteg-sos'}
                            style={{
                                // width: '80px',
                                // height: '80px',
                                background: ThemeColor.securityRed,
                                // fontSize: '1.6em',
                                // fontWeight: '900',
                                // color: 'white',
                                // borderRadius: '50%',
                                // position:'relative'
                            }}>
                            <div>SOS</div>
                        </div>

                        <div className='flex-row-center'>
                            <div style={{ fontSize: '1.4em', fontWeight: '900' }}>Emergency Call </div>
                        </div>
                    </div>
                </div>
                <div className='wlc_timeline_view_container' style={{ flex: 1, minWidth: "300px" }}>
                    <TimeLineView applianceId={device?.applianceId} />
                </div>
            </div>
        </>
    )
}

export default WZLTEG_1