import React, { useEffect, useRef, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import ThemeColor from '../../../assets/theme/ThemeColor';

const ExitEntryDelay = ({ entry_delay, exit_delay, setOverlay, onClick }) => {
    const numbers = Array.from({ length: 301 }, (v, i) => String(i));
    const [entryDelay, setEntryDelay] = useState(entry_delay);
    const [exitDelay, setExitDelay] = useState(exit_delay);
    const [topNumbers, setTopNumbers] = useState({ entryDelay: [], exitDelay: [] });
    const [bottomNumbers, setBottomNumbers] = useState({ entryDelay: [], exitDelay: [] });

    const entryDelayRef = useRef();
    const exitDelayRef = useRef();

    useEffect(() => {
        const entryTopNumbers = numbers.slice(0, Number(entryDelay));
        const entryBottomNumbers = numbers.slice(Number(entryDelay) + 1, 301);
        setTopNumbers(prev => ({ ...prev, entryDelay: entryTopNumbers }));
        setBottomNumbers(prev => ({ ...prev, entryDelay: entryBottomNumbers }));

        const time = setTimeout(() => {
            if (entryDelayRef.current) {
                entryDelayRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "start"
                });
                entryDelayRef.current.style.color = ThemeColor.securityDarkRed;
            }
        }, 100);

        return () => clearTimeout(time);
    }, [entryDelay]);

    useEffect(() => {
        const exitTopNumbers = numbers.slice(0, Number(exitDelay));
        const exitBottomNumbers = numbers.slice(Number(exitDelay) + 1, 301);
        setTopNumbers(prev => ({ ...prev, exitDelay: exitTopNumbers }));
        setBottomNumbers(prev => ({ ...prev, exitDelay: exitBottomNumbers }));

        const time = setTimeout(() => {
            if (exitDelayRef.current) {
                exitDelayRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "start"
                });
                exitDelayRef.current.style.color = ThemeColor.securityDarkRed;
            }
        }, 100);

        return () => clearTimeout(time);
    }, [exitDelay]);

    return (
        <div
            className='flex-column-top'
            style={{
                background: "white",
                borderRadius: "8px",
                boxShadow: "10p 1px 1px 10px red",
                width: '80%',
                padding: "20px 0px",
                margin: "0 auto",
                gap: "20px",
            }}>
            <div className='flex-row-center' style={{ position: "relative", width: "100%" }}>
                <div style={{ position: "absolute", left: "20px", top: "0px" }}>
                    <IoClose className='cursor-pointer' style={{ fontSize: "1.6em" }} onClick={() => setOverlay("close")} />
                </div>
                <h3>
                    <b>Exit/ Entry Delay</b>
                </h3>
                <div
                    className='cursor-pointer'
                    style={{ position: "absolute", right: "20px", top: "0px", color: ThemeColor.securityRed, fontSize: "1.2em" }}
                    onClick={() => { onClick(String(exitDelay), String(entryDelay)); setOverlay("close") }}
                >
                    save
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", padding: '0px 10px' }}>
                {/* exit delay */}
                <div style={{ flex: 1, width: "100%" }}>
                    <div className='flex-row-center' style={{ width: "90%", background: ThemeColor.bgColor, borderRadius: "6px", padding: "5px" }}>
                        <div style={{ color: "grey" }}>Exit Delay in seconds</div>
                    </div>
                    <div style={{ position: "relative", gap: "20px", overflowY: "scroll", overflowX: "hidden", width: "100%", textAlign: "center", height: "200px" }}>
                        {/* top numbers */}
                        {topNumbers.exitDelay.map((item, index) => (
                            <div key={"exitDelay-" + item}>
                                <div className='cursor-pointer' style={{ padding: "15px 0px" }} onClick={() => setExitDelay(item)}>{item}</div>
                            </div>
                        ))}
                        {/* data */}
                        <div ref={exitDelayRef} style={{ borderTop: `2px solid ${ThemeColor.securityRed}`, borderBottom: `2px solid ${ThemeColor.securityRed}` }}>
                            <div style={{ padding: "20px 0px", fontWeight: "900" }}>{exitDelay}</div>
                        </div>
                        {/* bottom numbers */}
                        {bottomNumbers.exitDelay.map((item, index) => (
                            <div key={"exitDelay-" + item}>
                                <div className='cursor-pointer' style={{ padding: "15px 0px" }} onClick={() => setExitDelay(item)}>{item}</div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* entry delay */}
                <div style={{ flex: 1, width: "100%" }}>
                    <div className='flex-row-center' style={{ width: "90%", background: ThemeColor.bgColor, borderRadius: "6px", padding: "5px" }}>
                        <div style={{ color: "grey" }}>Entry Delay in seconds</div>
                    </div>
                    <div style={{ position: "relative", gap: "20px", overflowY: "scroll", overflowX: "hidden", width: "100%", textAlign: "center", height: "200px" }}>
                        {/* top numbers */}
                        {topNumbers.entryDelay.map((item, index) => (
                            <div key={"entryDelay-" + item}>
                                <div className='cursor-pointer' style={{ padding: "15px 0px" }} onClick={() => setEntryDelay(item)}>{item}</div>
                            </div>
                        ))}
                        {/* data */}
                        <div ref={entryDelayRef} style={{ borderTop: `2px solid ${ThemeColor.securityRed}`, borderBottom: `2px solid ${ThemeColor.securityRed}` }}>
                            <div style={{ padding: "20px 0px", fontWeight: "900" }}>{entryDelay}</div>
                        </div>
                        {/* bottom numbers */}
                        {bottomNumbers.entryDelay.map((item, index) => (
                            <div key={"entryDelay-" + item}>
                                <div className='cursor-pointer' style={{ padding: "15px 0px" }} onClick={() => setEntryDelay(item)}>{item}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExitEntryDelay;
