import React, { useEffect, useRef, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import ThemeColor from '../../../assets/theme/ThemeColor'

const SirenDurationTime = ({ data = "0", setOverlay, onClick }) => {
    const numbers = Array.from({ length: 301 }, (v, i) => String(i));
    const [target, setTarget] = useState();
    const [topNumbers, setTopNumbers] = useState([]);
    const [bottomNumbers, setBottomNumbers] = useState([]);
    // const topNumbers = numbers.slice(0, Number(data)-1);
    // const bottomNumbers = numbers.slice(Number(data), 301);

    const sirenDurationRef = useRef();

    useEffect(() => {
        const l_topNumbers = numbers.slice(0, Number(target));
        const l_bottomNumbers = numbers.slice(Number(target) + 1, 301);
        setTopNumbers(l_topNumbers);
        setBottomNumbers(l_bottomNumbers);

        const time = setTimeout(() => {
            sirenDurationRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start"
            });
            sirenDurationRef.current.style.color = ThemeColor.securityDarkRed
        }, 100);
        // sirenDurationRef.current && sirenDurationRef.current.style.color=ThemeColor.securityDarkRed
        return () => clearTimeout(time);
    }, [target]);

    useEffect(() => {
        setTarget(data);

    }, [])

    return (
        <div
            className='flex-column-top'
            style={{

                background: "white",
                borderRadius: "8px",
                boxShadow: "10p 1px 1px 10px red",
                width: '80%',
                padding: "20px 0px",
                margin: "0 auto",
                gap: "20px",
                height: "290px",
                overflow: "scroll"
            }}>
            <div className='flex-row-center' style={{ position: "relative", width: "100%" }}>
                <div style={{ position: "absolute", left: "20px", top: "0px" }}>
                    <IoClose className='cursor-pointer' style={{ fontSize: "1.6em" }} onClick={() => setOverlay("close")} />
                </div>
                <h3>
                    <b>Wired Sensor Type</b>
                </h3>
                <div
                    className='cursor-pointer'
                    style={{ position: "absolute", right: "20px", top: "0px", color: ThemeColor.securityRed, fontSize: "1.2em" }}
                    onClick={() => { onClick(target); setOverlay("close") }}
                >
                    save
                </div>
            </div>
            <div className='flex-row-center' style={{ width: "90%", background: ThemeColor.bgColor, borderRadius: "6px", padding: "5px" }}>
                <div style={{ color: "grey" }}>in seconds</div>
            </div>
            <div style={{ position: "relative", gap: "20px", overflowY: "scroll", overflowX: "hidden", width: "100%", textAlign: "center" }}>
                {/* top numbers */}
                {topNumbers.map((item, index) => <div key={"sirenDuration-" + item}>
                    <div className='cursor-pointer' style={{ padding: "15px 0px" }} onClick={() => setTarget(item)} >{item}</div>
                </div>)}
                {/* data */}
                {/* <hr style={{ width:"100%", border:"1px solid red", }}/> */}
                <div ref={sirenDurationRef} style={{ borderTop: `2px solid ${ThemeColor.securityRed}`, borderBottom: `2px solid ${ThemeColor.securityRed}` }}>
                    <div style={{ padding: "20px 0px", fontWeight: "900" }}>{target}</div>
                </div>
                {/* <hr style={{ width:"100%", border:"1px solid red", }}/> */}
                {/* <hr/> */}
                {/* bottom numbers */}
                {bottomNumbers.map((item, index) => <div key={"sirenDuration-" + item}>
                    <div className='cursor-pointer' style={{ padding: "15px 0px" }} onClick={() => setTarget(item)}>{item}</div>
                </div>)}
            </div>
            {/* {[...Array(300)].map((item, index)=><div>{index}</div>)} */}
        </div>
    )
}

export default SirenDurationTime