import React from 'react'
import { FaUser } from 'react-icons/fa'
import { IoMdCloseCircle } from 'react-icons/io'

const RestrictedIcon = () => {
  return (
    <div style={{position:"relative"}}>
        <div className='flex-row-center' style={{
            border:"2px solid white", 
            borderRadius:"50%", 
            height:"100px", 
            width:"100px", 
            textAlign:"center", 
            // fontSize:"2em",
            // background:"rgb(0, 0, 0, 0.5)",
            // padding:"2px"
            // padding:'40px'
        }}>
            <FaUser/>
        </div>
        <div className='flex-row-center' style={{position:"absolute", left:"-12px", bottom:"-12px",  borderRadius:"50%"}}>
            <IoMdCloseCircle style={{fontSize:"1em"}}/>
        </div>
    </div>
  )
}

export default RestrictedIcon