/*!
*/

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";
import ThemeColor from "../../assets/theme/ThemeColor";
import ToggleTop from "../common/ToggleTop";
import { useUser } from "../../Context/context";
import RestrictedIcon from "../icons/RestrictedIcon";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ switchProperty, children }) {
  // const children = <h1>i am Inevitable</h1>
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState(ThemeColor.hashhPrimary);
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const {subscription} = useUser();

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  const {toggleTop, setToggleTop} = useUser();

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  

  useEffect(() => {
    // handleSubscription();
    // user.filter(item=>{
    //   console.log(item);
    //   /*  */
    // })
    
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }

    // console.log(switchProperty);
  }, []);
  
  console.log(subscription);
  return (
    <div style={subscription ? {}: {position:'relative', height:"100lvh", overflow:"hidden"}}>
      {
        subscription===false 
        && 
        <div className="flex-column-top" style={{
          height:'100%', 
          position:"absolute", 
          background:'rgb(0, 0, 0, 0.8)', 
          zIndex:1, 
          width:"100%",
          paddingTop:"18%",
          color:"white",
          fontSize:"3em",
          gap:"50px",
          overflow:"hidden"

          }}>
            <div>
              <RestrictedIcon/>
            </div>
            <div className="dynamic-font-1 flex-column-center" style={{}}>
              <div>
              Contact <b style={{color:ThemeColor.pirPrimary}}>HASHh</b> Team to access
              </div>
              <div className="dynamic-font-2">
                <a style={{color:ThemeColor.pirPrimary}} href="https://www.hashh.io/contact-us/">support@hashh</a>
              </div>
            </div>
            
        </div>
      }
      {toggleTop.toggle && <ToggleTop/>}
      <Layout
        className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""
          } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
      >

        <Drawer
          title={false}
          placement={placement === "right" ? "left" : "right"}
          closable={false}
          onClose={() => setVisible(false)}
          visible={visible}
          key={placement === "right" ? "left" : "right"}
          width={250}
          className={`drawer-sidebar ${pathname === "rtl" ? "drawer-sidebar-rtl" : ""
            } `}
        >

          <Layout
            className={`layout-dashboard ${pathname === "rtl" ? "layout-dashboard-rtl" : ""
              }`}
          >
            <Sider
              trigger={null}
              width={250}
              theme="light"
              className={`sider-primary ant-layout-sider-primary ${sidenavType === "#fff" ? "active-route" : ""
                }`}
              style={{ background: sidenavType }}
            >
                
              <Sidenav color={sidenavColor} />
            </Sider>
          </Layout>
        </Drawer>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {
            // console.log(collapsed, type);
          }}
          trigger={null}
          width={250}
          theme="light"
          className={`sider-primary ant-layout-sider-primary ${sidenavType === "#fff" ? "active-route" : ""
            }`}
          style={{ background: sidenavType }}
        >
          <Sidenav color={sidenavColor} subscription={subscription}/>
        </Sider>
        <Layout>
          {fixed ? (
            <Affix>
              <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                <Header
                  onPress={openDrawer}
                  name={pathname}
                  subName={pathname}
                  handleSidenavColor={handleSidenavColor}
                  handleSidenavType={handleSidenavType}
                  handleFixedNavbar={handleFixedNavbar}
                />
              </AntHeader>
            </Affix>
          ) : (
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header
                onPress={openDrawer}
                name={pathname}
                subName={pathname}
                handleSidenavColor={handleSidenavColor}
                handleSidenavType={handleSidenavType}
                handleFixedNavbar={handleFixedNavbar}
                switchProperty={switchProperty}
              />
            </AntHeader>
          )}
          <Content style={{marginBottom:10}} className="content-ant">{children}</Content>
        </Layout>
      </Layout>
        <Footer />
    </div>

  );
}

export default Main;
