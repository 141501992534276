import { CheckCircleFilled } from '@ant-design/icons'
import React from 'react'
import { FaRegCircle } from 'react-icons/fa6'
import { IoClose } from 'react-icons/io5'
import ThemeColor from '../../../assets/theme/ThemeColor'

const WiredSensorTypeSettings = ({data, onClick, setOverlay}) => {

    return (
        <div
            className='flex-column-center'
            style={{

                background: "white",
                borderRadius: "8px",
                boxShadow: "10p 1px 1px 10px red",
                width: '80%',
                padding: "20px",
                margin: "0 auto",
                gap: "20px"
            }}>
            <div className='flex-row-center' style={{ position: "relative", width: "100%" }}>
                <div style={{ position: "absolute", left: "0px", top: "0px" }}>
                    <IoClose className='cursor-pointer' style={{ fontSize: "2em" }} onClick={()=>setOverlay("close")}/>
                </div>
                <h3>
                    <b>Wired Sensor Type</b>
                </h3>
            </div>
            <div className='flex-row-center' style={{ width: "90%", background: ThemeColor.bgColor, borderRadius: "6px", padding: "5px", position: "relative" }}>
                <div style={{ fontSize: "2em", position: "absolute", left: "10px", }}>
                    {data==="0"? <CheckCircleFilled style={{ color: ThemeColor.securityRed }} /> : <FaRegCircle className='cursor-pointer' onClick={()=>onClick("0")} />}
                </div>
                <div style={{ fontSize: "1.3em" }}>N.O</div>
            </div>
            <div className='flex-row-center' style={{ width: "90%", background: ThemeColor.bgColor, borderRadius: "6px", padding: "5px", position: "relative" }}>
                <div style={{ fontSize: "2em", position: "absolute", left: "10px", }}>
                    {data==="1" ? <CheckCircleFilled style={{ color: ThemeColor.securityRed }} /> : <FaRegCircle className='cursor-pointer' onClick={()=>onClick("1")} />}
                </div>
                <div style={{ fontSize: "1.3em" }}>N.C</div>
            </div>
        </div>
    )
}

export default WiredSensorTypeSettings