import React from 'react'
import { MdCancel } from 'react-icons/md';

const Overlay = ({children, overlay, setOverlay, zIndex, closeBtn=true}) => {
    
    zIndex = zIndex ?? 22;
    if(overlay === 'close') setTimeout(()=> setOverlay(''), 200);

    return (
    <>
        {overlay !== '' && (
            <div 
                style={{zIndex}}
                className={(overlay === 'open' || overlay === '') ?
                    `overlay overlay-animation-open` :
                    (overlay === 'close' || overlay === '') ?
                        `overlay overlay-animation-close` :
                        `overlay`
                } 
                onClick={() => setOverlay('close')}
            >
                <div style={{width:"100%"}} onClick={(e) => e.stopPropagation()}>
                    {children}
                    {/* <div style={{color:'black', width:'50px', height:'50px',borderRadius:'50%', background:'white', position:'absolute', top:'20px', textAlign:'center'}}> */}
                        {closeBtn && <MdCancel 
                            style={{fontSize:'20px', position:'absolute', top:'10px',right:'10px', zIndex:zIndex+1, color:'white', fontSize:'45px'}}
                            onClick={()=>setOverlay('close')}
                        />}
                    {/* </div> */}
                </div>
            </div>
        )}
    </>
  )
}

export default Overlay